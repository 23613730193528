<template>
  <div>
    <v-row class="mt-4">
      <!-- Planogram -->
      <div class="planogram">
        <div class="planogram-grid d-flex align-center;">
          <div class="tabs">
            <div class="tab">
              <input
                type="radio"
                :id="this.planogram.name + 'rd3'"
                name="rd"
                checked="true"
              />
              <label class="tab-label" :for="this.planogram.name + 'rd3'">{{
                $t("drawingArea")
              }}</label>
              <div class="tab-content">
                <div
                  :id="this.planogram.name + 'ShelvesPalette'"
                  class="shelves-palette"
                ></div>
              </div>
            </div>
            <div class="tab">
              <input type="radio" :id="this.planogram.name + 'rd2'" name="rd" />
              <label class="tab-label" :for="this.planogram.name + 'rd2'">{{
                $t("entryExit")
              }}</label>
              <div class="tab-content">
                <div
                  :id="this.planogram.name + 'EntryExitPalette'"
                  class="entry-exit-palette"
                ></div>
              </div>
            </div>
            <div class="tab">
              <input type="radio" :id="this.planogram.name + 'rd1'" name="rd" />
              <label class="tab-label" :for="this.planogram.name + 'rd1'">{{
                $t("categories")
              }}</label>
              <div class="tab-content">
                <div
                  :id="this.planogram.name + 'myPaletteSmall'"
                  class="categories-palette"
                ></div>
              </div>
            </div>
          </div>
        </div>
        <div
          :id="this.planogram.name"
          style="flex-grow: 1; height: 500px; border: solid 1px #9b9b9b"
        ></div>
      </div>
      <!-- !Planogram -->
    </v-row>
    <!-- Buttons -->
    <v-row class="d-flex justify-end mt-8 mb-2">
      <v-btn
        @click="deleteSimulator"
        color="red lighten-1 white--text"
        class="mr-4"
        style="text-transform: unset !important"
        ><v-icon left> mdi-trash-can-outline </v-icon>{{ $t("delete") }}</v-btn
      >
      <v-btn
        @click="editSimulator"
        color="primary"
        style="text-transform: unset !important"
        ><v-icon left> mdi-pencil </v-icon>{{ $t("edit") }}</v-btn
      >
    </v-row>
    <!-- !Buttons -->
    <!-- Products Dialog -->
    <v-dialog
      v-model="$store.getters['planogram/productsDialog']"
      width="700"
      @click:outside="$store.dispatch('planogram/hideProductsDialog')"
      style="color: black"
    >
      <v-card>
        <v-card-title>
          {{ $store.getters["planogram/name"] }}
          <v-spacer></v-spacer>
        </v-card-title>
        <v-card-text>
          <br />
          <v-data-table
            :headers="headers"
            :items="$store.getters['planogram/products']"
            :items-per-page="4"
            class="elevation-2"
          >
          </v-data-table>
        </v-card-text>
        <planogramProductsView
          v-if="$store.getters['planogram/productsDialog']"
          :category="$store.getters['planogram/category']"
          :simulatorName="this.planogram.name"
        />
        <v-divider></v-divider>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
            color="primary"
            text
            @click="$store.dispatch('planogram/hideProductsDialog')"
          >
            {{ $t("close") }}
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <!-- Products Dialog -->
  </div>
</template>
<style lang="scss" scoped>
input {
  position: absolute;
  opacity: 0;
  z-index: -1;
}
/* Accordion styles */
.tabs {
  overflow: hidden;
}

.tab {
  width: 100%;
  color: #535353;
  overflow: hidden;
}
.tab-label {
  display: flex;
  justify-content: space-between;
  padding: 1rem;
  background: #dddddd;
  cursor: pointer;

  &:first-of-type {
    margin-bottom: 8px;
  }
}
.tab-label:hover {
  background: #535353;
  color: white;
}
.tab-label::after {
  content: "❯";
  width: 1em;
  height: 1em;
  text-align: center;
  transition: all 0.35s;
}
.tab-content {
  max-height: 0;
  color: #535353;
  background: white;
}

input:checked + .tab-label {
  background: #535353;
  color: white;
}
input:checked + .tab-label::after {
  transform: rotate(90deg);
}
input:checked ~ .tab-content {
  max-height: 100vh;
}

input[type="radio"] + label:before {
  display: none;
}

/* !Accordion styles */

.planogram {
  width: 100%;
  display: flex;
  justify-content: space-between;
}

.planogram-grid {
  width: 245px;
  margin-right: 2px;
  background-color: white;
  border: solid 1px #9b9b9b;
}

.shelves-palette {
  width: 245px;
  height: 120px;
}

.entry-exit-palette {
  width: 245px;
  height: 80px;
}

.categories-palette {
  width: 244px;
  height: 370px;
}
</style>
<script>
// @ is an alias to /src
import * as go from "gojs";
import store from "../store";
import AppError from "@/utils/AppError.js";
import planogramProductsView from "@/components/PlanogramProductsView.vue";

let AllowTopLevel = false;
let CellSize = new go.Size(50, 50);
let myDiagram = null;
go.Diagram.licenseKey =
  "73f944e5b46f31b700ca0d2b113f69ee1bb37b3a9e841af95e5342f5ee0c68402a98ed7c58d58fc1d4aa4ffe197bc28d8ec16d2d855c0368b336d2db10e5d1f9b03573b11c0f428df45022979efb2ba7fb2a72fa91e773a78d2d8ff0edac969c09eff6d518981cab2f2d5637562cff4ba5ebda7afa01d346746d9f";

export default {
  name: "planogram-simulator",

  components: {
    planogramProductsView,
  },

  props: {
    planogram: {
      type: Object,
      required: true,
    },
  },

  mounted: function () {
    this.getStore();
    this.getLoadedDiagram();
    this.getCategories();
  },

  data() {
    return {
      categories: null,
      loadedDiagram: null,
      headers: [
        {
          text: this.$t("description"),
          align: "start",
          value: "description",
        },
        {
          text: this.$t("sku"),
          value: "SKU",
        },
      ],
    };
  },

  methods: {
    /**
     * Get the loaded planogram.
     * @public
     */
    getLoadedDiagram() {
      this.$store.dispatch("util/startLoading");
      this.loadedDiagram = this.planogram.diagram;
    },
    /**
     * Delete simulator.
     * @public
     */
    deleteSimulator() {
      /*this.$store.dispatch("util/startLoading");
      let url = this.$config.api.route.simulatorsActions;
      url = url.replace(":id", this.store.id);
      url = url.replace(":name", this.planogram.name);
      this.$api({
        url: url,
        method: "DELETE",
      })
        .then(() => {
          this.$store.dispatch(
            "alert/showSuccess",
             this.$t('simulatorDeleted')
          );
        })
        .catch((err) => {
          AppError.handle(err);
        })
        .finally(() => {
          this.$store.dispatch("util/stopLoading");
        });*/
    },
    /**
     * Edit simulator.
     * @public
     */
    editSimulator() {
      this.$store.dispatch("util/startLoading");
      let url = this.$config.api.route.planogram;
      url = url.replace(":id", this.store.id);

      this.$api({
        url: url,
        data: {
          name: this.planogram.name,
          planogram: myDiagram.model.toJson(),
        },
        method: "POST",
      })
        .then(() => {
          this.$store.dispatch("alert/showSuccess", this.$t("simulatorEdited"));
        })
        .catch((err) => {
          AppError.handle(err);
        })
        .finally(() => {
          this.$store.dispatch("util/stopLoading");
        });
    },
    /**
     * Get the selected store.
     * @public
     */
    getStore() {
      this.store = JSON.parse(localStorage.getItem("store"));
    },
    /**
     * Get categories.
     * @public
     */
    getCategories() {
      let url = this.$config.api.route.categories;
      url = url.replace(":id", this.store.id);

      this.$api({
        url: url,
        method: "GET",
      })
        .then((resp) => {
          this.categories = resp.data;
        })
        .catch((err) => {
          AppError.handle(err);
        })
        .then(() => {
          this.buildPlanogram();
        })
        .finally(() => {
          this.$store.dispatch("util/stopLoading");
        });
    },
    /**
     * Build diagram inside 'myDiagramDiv'.
     * @public
     */
    buildPlanogram() {
      const $ = go.GraphObject.make;

      myDiagram = $(go.Diagram, this.planogram.name, {
        grid: $(
          go.Panel,
          "Grid",
          { gridCellSize: CellSize, background: "white" },
          $(go.Shape, "LineH", { stroke: "lightgray" }),
          $(go.Shape, "LineV", { stroke: "lightgray" })
        ),
        "draggingTool.isGridSnapEnabled": true,
        "draggingTool.gridSnapCellSpot": go.Spot.Center,
        "resizingTool.isGridSnapEnabled": true,
        "animationManager.isEnabled": false,
        "undoManager.isEnabled": true,
      });

      if (this.loadedDiagram != null) {
        // Getting data from json
        myDiagram.model = go.Model.fromJson(this.loadedDiagram);
      }

      myDiagram.commandHandler.doKeyDown = function () {
        // must be a function, not an arrow =>
        var e = this.diagram.lastInput;
        var key = e.key;
        var selectedNode = myDiagram.selection.first();
        var node = selectedNode.data;
        if (
          (key === "Del" && node.products) ||
          (key === "Backspace" && node.products)
        )
          myPaletteSmall.model.addNodeData({
            key: selectedNode.data.key,
            name: selectedNode.data.name,
            color: selectedNode.data.color,
            size: "150 50",
            products: selectedNode.data.products,
            category: "normal",
          });
        // call base method with no arguments (default functionality)
        go.CommandHandler.prototype.doKeyDown.call(this);
      };

      // Regular Nodes represent items to be put onto racks.

      // Normal template used for category and entry/exit nodes.
      var normalTemplate = $(
        go.Node,
        "Auto",
        {
          resizable: true,
          resizeObjectName: "SHAPE",
          locationSpot: new go.Spot(
            0,
            0,
            CellSize.width / 2,
            CellSize.height / 2
          ),

          doubleClick: function (e, node) {
            // Verify if a node is entry or exit and don't show the modal, otherwise show modal with products.
            if (node.data.key == "Entry" || node.data.key == "Exit") {
              return;
            } else {
              store.dispatch("planogram/showProductsDialog");
              store.dispatch("planogram/addCategoryDialog", node.data);
            }
          },
          // Provide a visual warning about dropping anything onto an "item".
          mouseDragEnter: function (e, node) {
            e.handled = true;
            node.findObject("SHAPE").fill = "red";
            e.diagram.currentCursor = "not-allowed";
            highlightGroup(node.containingGroup, false);
          },
          mouseDragLeave: function (e, node) {
            node.updateTargetBindings();
          },
          mouseDrop: function (e, node) {
            // Disallow dropping anything onto an "item".
            node.diagram.currentTool.doCancel();
          },
        },
        // Always save/load the point that is the top-left corner of the node.
        new go.Binding("position", "pos", go.Point.parse).makeTwoWay(
          go.Point.stringify
        ),

        $(
          go.Shape,
          "Rectangle",
          {
            name: "SHAPE",
            fill: "white",
            stroke: "#9b9b9b",
            minSize: CellSize,
            desiredSize: CellSize,
          },
          new go.Binding("fill", "color"),
          new go.Binding("desiredSize", "size", go.Size.parse).makeTwoWay(
            go.Size.stringify
          )
        ),
        $(
          go.TextBlock,
          { alignment: go.Spot.Center, font: "bold 14px sans-serif" },
          new go.Binding("text", "key")
        )
      );
      // end Node

      // Heatmap Template used for heatmap nodes.
      var heatMapTemplate = $(
        go.Node,
        "Auto",
        {
          resizeObjectName: "HEATMAP",
          locationSpot: new go.Spot(
            0,
            0,
            CellSize.width / 2,
            CellSize.height / 2
          ),

          // Provide a visual warning about dropping anything onto an "item".
          mouseDragEnter: function (e, node) {
            e.handled = true;
            node.findObject("SHAPE").fill = "red";
            e.diagram.currentCursor = "not-allowed";
            highlightGroup(node.containingGroup, false);
          },
          mouseDragLeave: function (e, node) {
            node.updateTargetBindings();
          },
          mouseDrop: function (e, node) {
            // Disallow dropping anything onto an "item".
            node.diagram.currentTool.doCancel();
          },
        },

        new go.Binding("position", "pos", go.Point.parse).makeTwoWay(
          go.Point.stringify
        ),

        $(
          go.Shape,
          "Rectangle",
          {
            name: "SHAPE",
            fill: "white",
            minSize: CellSize,
            desiredSize: CellSize,
          },
          new go.Binding("fill", "color"),
          new go.Binding("desiredSize", "size", go.Size.parse).makeTwoWay(
            go.Size.stringify
          )
        )
      );

      var templmap = new go.Map();

      // Add all templates to diagram.
      templmap.add("normal", normalTemplate);
      templmap.add("heatmap", heatMapTemplate);
      templmap.add("", myDiagram.nodeTemplate);

      myDiagram.nodeTemplateMap = templmap;

      // Groups represent racks where items (Nodes) can be placed.
      // Groups provide feedback when the user drags nodes onto them.
      function highlightGroup(grp, show) {
        if (!grp) return false;
        // Check that the drop may really happen into the Group.
        var tool = grp.diagram.toolManager.draggingTool;
        grp.isHighlighted = show && grp.canAddMembers(tool.draggingParts);
        return grp.isHighlighted;
      }

      var groupFill = "rgb(115, 115, 115, 0.4)";
      var groupStroke = "gray";
      var dropFill = "rgba(128,255,255,0.2)";
      var dropStroke = "blue";

      myDiagram.groupTemplate = $(
        go.Group,
        {
          layerName: "Background",
          resizable: true,
          resizeObjectName: "SHAPE",
          locationSpot: new go.Spot(
            0,
            0,
            CellSize.width / 2,
            CellSize.height / 2
          ),
        },
        new go.Binding("position", "pos", go.Point.parse).makeTwoWay(
          go.Point.stringify
        ),
        {
          // What to do when a drag-over or a drag-drop occurs on a Group.
          mouseDragEnter: function (e, grp) {
            if (!highlightGroup(grp, true))
              e.diagram.currentCursor = "not-allowed";
            else e.diagram.currentCursor = "";
          },
          mouseDragLeave: function (e, grp) {
            highlightGroup(grp, false);
          },
          mouseDrop: function (e, grp) {
            var ok = grp.addMembers(grp.diagram.selection, true);
            if (!ok) grp.diagram.currentTool.doCancel();
          },
        },
        $(
          go.Shape,
          "Rectangle",
          {
            name: "SHAPE",
            fill: groupFill,
            stroke: groupStroke,
            minSize: new go.Size(CellSize.width, CellSize.height),
          },
          new go.Binding("desiredSize", "size", go.Size.parse).makeTwoWay(
            go.Size.stringify
          ),
          new go.Binding("fill", "isHighlighted", function (h) {
            return h ? dropFill : groupFill;
          }).ofObject(),
          new go.Binding("stroke", "isHighlighted", function (h) {
            return h ? dropStroke : groupStroke;
          }).ofObject()
        )
      );

      // Decide what kinds of Parts can be added to a Group
      myDiagram.commandHandler.memberValidation = function (grp, node) {
        // cannot add Groups to Groups
        if (grp instanceof go.Group && node instanceof go.Group) return false;
        return true;
      };

      // What to do when a drag-drop occurs in the Diagram's background
      myDiagram.mouseDragOver = function (e) {
        if (!AllowTopLevel) {
          // OK to drop a group anywhere or any Node that is a member of a dragged Group
          var tool = e.diagram.toolManager.draggingTool;
          if (
            !tool.draggingParts.all(function (p) {
              return (
                p instanceof go.Group ||
                (!p.isTopLevel &&
                  tool.draggingParts.contains(p.containingGroup))
              );
            })
          ) {
            e.diagram.currentCursor = "not-allowed";
          } else {
            e.diagram.currentCursor = "";
          }
        }
      };

      myDiagram.mouseDrop = function (e) {
        if (AllowTopLevel) {
          // When the selection is dropped in the diagram's background,
          // Make sure the selected Parts no longer belong to any Group
          if (
            !e.diagram.commandHandler.addTopLevelParts(
              e.diagram.selection,
              true
            )
          ) {
            e.diagram.currentTool.doCancel();
          }
        } else {
          // Disallow dropping any regular nodes onto the background, but allow dropping "racks",
          // Including any selected member nodes
          if (
            !e.diagram.selection.all(function (p) {
              return (
                p instanceof go.Group ||
                (!p.isTopLevel && p.containingGroup.isSelected)
              );
            })
          ) {
            e.diagram.currentTool.doCancel();
          }
        }
      };

      // Create array with category and its products to create nodes.
      var categories = [];
      if (this.categories) {
        this.categories.map((category) => {
          var categoryProducts = [];

          if (category.products != null) {
            category.products.map((product) => {
              categoryProducts.push(product);
            });
          }

          var newCategory = {
            key: category.code,
            name: category.name,
            color: category.color,
            size: "150 50",
            products: categoryProducts,
            category: "normal",
          };
          myDiagram.model.nodeDataArray.forEach(function (node) {
            if (newCategory.key === node.key) {
              myDiagram.model.setDataProperty(node, "color", newCategory.color);
            }
          });
          categories.push(newCategory);
        });
      }

      // Compare function to sort alphabetically the categories.
      function compare(a, b) {
        if (a.key < b.key) {
          return -1;
        }
        if (a.key > b.key) {
          return 1;
        }
        return 0;
      }

      // Sort categories
      categories.sort(compare);

      let myPaletteSmall = $(
        go.Palette,
        this.planogram.name + "myPaletteSmall",
        {
          nodeTemplateMap: myDiagram.nodeTemplateMap,
          groupTemplate: myDiagram.groupTemplate,
        }
      );

      // Specify the contents of the Palette
      myPaletteSmall.model = new go.GraphLinksModel(categories);

      let EntryExitPalette = $(
        go.Palette,
        this.planogram.name + "EntryExitPalette",
        {
          nodeTemplateMap: myDiagram.nodeTemplateMap,
          groupTemplate: myDiagram.groupTemplate,
        }
      );

      // Specify the contents of the Palette
      EntryExitPalette.model = new go.GraphLinksModel([
        {
          key: this.$t("entry"),
          color: "#C5DDAF",
          size: "50 50",
          category: "normal",
        },
        {
          key: this.$t("exit"),
          color: "#DDBAB9",
          size: "50 50",
          category: "normal",
        },
      ]);

      let ShelvesPalette = $(
        go.Palette,
        this.planogram.name + "ShelvesPalette",
        {
          nodeTemplateMap: myDiagram.nodeTemplateMap,
          groupTemplate: myDiagram.groupTemplate,
        }
      );

      // Specify the contents of the Palette
      ShelvesPalette.model = new go.GraphLinksModel([
        { key: "Shelf", isGroup: true, size: "100 100", category: "normal" },
      ]);
    },
  },
};
</script>
