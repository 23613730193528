<template>
  <div class="simulator">
    <v-row>
      <v-hover v-slot="{ hover }">
        <v-btn
          :to="'NewSimulator'"
          color="white--text"
          :style="{ 'background-color': hover ? '#002147' : '#535353' }"
          style="text-transform: unset !important"
          >{{ $t("createSimulator") }}</v-btn
        >
      </v-hover>
    </v-row>
    <v-row class="mt-10">
      <h1>{{ $t("planogramSimulations") }}</h1>
    </v-row>

    <v-row class="mt-8">
      <v-expansion-panels accordion>
        <v-expansion-panel v-for="(item, i) in this.planograms" :key="i">
          <v-expansion-panel-header> {{ item.name }}</v-expansion-panel-header>
          <v-expansion-panel-content>
            <planogram-simulator :planogram="item" />
          </v-expansion-panel-content>
        </v-expansion-panel>
      </v-expansion-panels>
    </v-row>
  </div>
</template>
<script>
// @ is an alias to /src
import planogramFile from "@/utils/planogram.json";
import planogramFile2 from "@/utils/planogram2.json";
import planogramSimulator from "@/components/PlanogramSimulator.vue";

export default {
  name: "simulator",

  components: {
    planogramSimulator,
  },

  mounted: function () {
    this.scrollToTop();
    this.getPlanograms();
  },

  data() {
    return {
      planograms: [],
    };
  },

  methods: {
    /**
     * Scroll to the top of the page.
     * @public
     */
    scrollToTop() {
      window.scrollTo(0, 0);
    },
    /**
     * Get a list with all planograms.
     * @public
     */
    getPlanograms() {
      //get planograms from database
      /*
      var store = JSON.parse(localStorage.getItem("store"));
      let url = this.$config.api.route.usersStores;
      url = url.replace(":id", store.id);
      this.$store.dispatch("util/startLoading");
      this.$api({
        url: url,
        method: "GET",
      })
        .then((resp) => {
            this.planograms = resp.data;
          }
        })
        .catch((err) => {
          AppError.handle(err);
        })
        .finally(() => {
          this.$store.dispatch("util/stopLoading");
        });*/
      this.planograms.push(planogramFile);
      this.planograms.push(planogramFile2);
    },
  },
};
</script>
