<template>
  <div>
    <!-- Planogram -->
    <div class="planogram-products pb-12">
      <div
        :id="
          this.simulatorName
            ? this.simulatorName + this.category.key
            : this.category.key + 'view'
        "
        :style="
          this.simulatorName
            ? 'flex-grow: 1; height: 500px; border: solid 1px black'
            : 'flex-grow: 1; height: 350px; border: solid 1px black'
        "
      ></div>
    </div>
    <!-- !Planogram -->
  </div>
</template>
<style scoped>
input {
  position: absolute;
  opacity: 0;
  z-index: -1;
}

.planogram-products {
  width: 100%;
  display: flex;
  justify-content: space-between;
}
</style>
<script>
// @ is an alias to /src
import * as go from "gojs";
import AppError from "@/utils/AppError.js";
let CellSize = new go.Size(50, 50);
let myDiagram = null;
go.Diagram.licenseKey =
  "73f944e5b46f31b700ca0d2b113f69ee1bb37b3a9e841af95e5342f5ee0c68402a98ed7c58d58fc1d4aa4ffe197bc28d8ec16d2d855c0368b336d2db10e5d1f9b03573b11c0f428df45022979efb2ba7fb2a72fa91e773a78d2d8ff0edac969c09eff6d518981cab2f2d5637562cff4ba5ebda7afa01d346746d9f";

export default {
  name: "planogram-products",

  props: {
    category: {
      type: Object,
      required: true,
    },
    simulatorName: {
      type: String,
      default: null,
    },
  },

  mounted: function () {
    this.getStore();
    this.getLoadedDiagram();
  },

  data() {
    return {
      loadedDiagram: null,
      store: null,
    };
  },

  methods: {
    /**
     * Get the selected store.
     * @public
     */
    getStore() {
      this.store = JSON.parse(localStorage.getItem("store"));
    },
    /**
     * Get the loaded planogram.
     * @public
     */
    getLoadedDiagram() {
      this.$store.dispatch("util/startLoading");
      let url = this.$config.api.route.productsShelf;
      url = url.replace(":id", this.store.id);
      url = url.replace(":code", this.category.key);
      this.$store.dispatch("util/startLoading");
      this.$api({
        url: url,
        method: "GET",
      })
        .then((resp) => {
          this.loadedDiagram = resp.data;
        })
        .catch((err) => {
          AppError.handle(err);
        })
        .finally(() => {
          this.buildPlanogram();
          this.$store.dispatch("util/stopLoading");
        });
    },

    /**
     * Build diagram inside 'myDiagramDiv'.
     * @public
     */
    buildPlanogram() {
      const $ = go.GraphObject.make;

      myDiagram = $(
        go.Diagram,
        this.simulatorName
          ? this.simulatorName + this.category.key
          : this.category.key + "view",
        {
          grid: $(
            go.Panel,
            "Grid",
            { gridCellSize: CellSize, background: "white" },
            $(go.Shape, "LineH", { stroke: "lightgray" }),
            $(go.Shape, "LineV", { stroke: "lightgray" })
          ),
          "draggingTool.isGridSnapEnabled": true,
          "draggingTool.gridSnapCellSpot": go.Spot.Center,
          "resizingTool.isGridSnapEnabled": true,
          "animationManager.isEnabled": false,
          "undoManager.isEnabled": true,
          isReadOnly: true,
        }
      );

      if (this.loadedDiagram) {
        // Getting data from json
        myDiagram.model = go.Model.fromJson(this.loadedDiagram);
        //Images in nodes
        /*myDiagram.model.nodeDataArray.forEach((node) => {
          node.url =
            "data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAOEAAADhCAMAAAAJbSJIAAAAM1BMVEVQlM3///9BjcpIkczb5/RLksxQlc3h6/VYmM/0+Px/rthGj8tHkczk7PbC1+zd6fRfnNGAR1eNAAACXElEQVR4nO3cWa6iABhEYdDWi3Kn/a+2cWZ6a/4qU33OAkh9MVEEtGmIiIiIiIiIiIiIiIiIiIiIiIiIiIiIiIgCOrgHVNede/eE6tp04rFNJw7CcOJFmE28CqOJN2Ey8S4MJj6EucSnMJb4EqYSR8JQ4liYSZwII4lTYSJxJgwkzoVxxEMzFzqIh31dn/uFUE/cfSxH1KYm6oVqokEoJjqEWqJFKCV6hEqiSSgkuoQ6ok3YnvfpwpPoRbQJT50GaBOeOtWNN5NQBzQJhUCPUAm0CKVAh1ALbHZf4cCm//75U5gfOBB3lb0BsLbF1cQ04EIYB5wL84AzYSBwKkwEToSRwLEwEzgShgJfwlTgUxgLfAhzgXdhMPAmTAZehdHAizAbOAjfErjhpON7An+3u97e/b4jsDsdNzzadofaqgHYujeUdgFGC6/AZOENGCy8A3OFD2Cs8AlMFb6AocIRMFM4BkYKJ8BE4RQYKJwB84RzYJxwAUwTLoFhwhVgW3pnX/1zizVgW/lwxs+3lrgKrO1rFw5sP5RCB1AqtACVQg9QKDQBdUIXUCd0AWXC3gWUCffndGHTu4i691IXUfh5aCIqz2k8ROl5qYUoFVqIWqGDKBYaiGrhKnH/WfhfGfpnF1aI7/gExb+0JLoXbd6C6B60fXOie09BM6J7TkVTontNSROie0xNY6J7S1EjontKVS+ie0lZT6J7SF0PontHYXeie0ZlN6J7RWlXontEbReie0NxA9E9obr+rPp/I1th3/CJiIiIiIiIiIiIiIiIiIiIiIiIiIiIiOh/7S+QaSXJ1R+BzAAAAABJRU5ErkJggg==";
        });*/
      }

      // Regular Nodes represent items to be put onto racks.

      // Normal template used for category and entry/exit nodes.
      var normalTemplate = $(
        go.Node,
        "Auto",
        {
          resizable: true,
          resizeObjectName: "SHAPE",
          locationSpot: new go.Spot(
            0,
            0,
            CellSize.width / 2,
            CellSize.height / 2
          ),

          mouseEnter: function (e, node) {
            var nodeContextMenu = $(
              go.Adornment,
              "Spot",
              { background: "transparent" },
              $(go.Placeholder),
              $(go.Shape, {
                figure: "RoundedRectangle",
                fill: node.data.color,
                height: 40,
                width: 370,
              }),
              $(
                go.Panel,
                "Horizontal",
                { margin: 8 },

                $(
                  go.TextBlock,
                  {
                    text:
                      node.data.name +
                      "\nWidth: " +
                      node.data.width +
                      " Depth: " +
                      node.data.depth,
                  },
                  {
                    stroke: "black",
                    height: 40,
                    alignment: go.Spot.Center,
                    verticalAlignment: go.Spot.Center,
                  }
                )
              )
            );

            nodeContextMenu.adornedObject = node;
            nodeContextMenu.mouseLeave = function () {
              node.removeAdornment("ContextMenuOver");
            };
            node.addAdornment("ContextMenuOver", nodeContextMenu);
          },

          // Provide a visual warning about dropping anything onto an "item".
          mouseDragEnter: function (e, node) {
            e.handled = true;
            node.findObject("SHAPE").fill = "red";
            e.diagram.currentCursor = "not-allowed";
            highlightGroup(node.containingGroup, false);
          },
          mouseDragLeave: function (e, node) {
            node.updateTargetBindings();
          },
          mouseDrop: function (e, node) {
            // Disallow dropping anything onto an "item".
            node.diagram.currentTool.doCancel();
          },
        },

        // Always save/load the point that is the top-left corner of the node.
        new go.Binding("position", "pos", go.Point.parse).makeTwoWay(
          go.Point.stringify
        ),

        $(
          go.Shape,
          "Rectangle",
          {
            name: "SHAPE",
            fill: "white",
            minSize: CellSize,
            desiredSize: CellSize,
          },
          new go.Binding("fill", "color"),
          new go.Binding("desiredSize", "size", go.Size.parse).makeTwoWay(
            go.Size.stringify
          )
        ),
        $(
          go.Panel,
          "Table",
          // Image block
          /* $(
            go.Picture,
            {
              width: 45,
              height: 45,
              column: 0,
              margin: new go.Margin(10, 10), // Adjust the top and bottom margins as needed
              imageStretch: go.GraphObject.Uniform,
            },
            // eslint-disable-next-line no-unused-vars
            { sourceCrossOrigin: (pict) => "use-credentials" },
            new go.Binding("source", "", (sourceData) => {
              let fileSvg = sourceData.url;

              return fileSvg;
            })
          ), */
          // Text block
          $(
            go.TextBlock,
            { column: 1, font: "bold 12pt sans-serif" },
            new go.Binding("text", "key")
          )
        )
      );
      // end Node

      var templmap = new go.Map();

      // Add all templates to diagram.
      templmap.add("normal", normalTemplate);
      templmap.add("", myDiagram.nodeTemplate);
      myDiagram.nodeTemplateMap = templmap;

      // Groups represent racks where items (Nodes) can be placed.
      // Groups provide feedback when the user drags nodes onto them.
      function highlightGroup(grp, show) {
        if (!grp) return false;
        // Check that the drop may really happen into the Group.
        var tool = grp.diagram.toolManager.draggingTool;
        grp.isHighlighted = show && grp.canAddMembers(tool.draggingParts);
        return grp.isHighlighted;
      }

      var groupFill = "rgb(115, 115, 115, 0.4)";
      var groupStroke = "gray";
      var dropFill = "rgba(128,255,255,0.2)";
      var dropStroke = "blue";

      myDiagram.groupTemplate = $(
        go.Group,
        {
          layerName: "Background",
          resizable: true,
          resizeObjectName: "SHAPE",
          locationSpot: new go.Spot(
            0,
            0,
            CellSize.width / 2,
            CellSize.height / 2
          ),
        },
        new go.Binding("position", "pos", go.Point.parse).makeTwoWay(
          go.Point.stringify
        ),
        {
          // What to do when a drag-over or a drag-drop occurs on a Group.
          mouseDragEnter: function (e, grp) {
            if (!highlightGroup(grp, true))
              e.diagram.currentCursor = "not-allowed";
            else e.diagram.currentCursor = "";
          },
          mouseDragLeave: function (e, grp) {
            highlightGroup(grp, false);
          },
          mouseDrop: function (e, grp) {
            var ok = grp.addMembers(grp.diagram.selection, true);
            if (!ok) grp.diagram.currentTool.doCancel();
          },
        },
        $(
          go.Shape,
          "Rectangle",
          {
            name: "SHAPE",
            fill: groupFill,
            stroke: groupStroke,
            minSize: new go.Size(CellSize.width, CellSize.height),
          },
          new go.Binding("desiredSize", "size", go.Size.parse).makeTwoWay(
            go.Size.stringify
          ),
          new go.Binding("fill", "isHighlighted", function (h) {
            return h ? dropFill : groupFill;
          }).ofObject(),
          new go.Binding("stroke", "isHighlighted", function (h) {
            return h ? dropStroke : groupStroke;
          }).ofObject()
        )
      );
    },
  },
};
</script>
